"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _goodReceivedNote = require("@/api/goodReceivedNote");
var _warehouse = require("@/api/warehouse");
var _warehouseArea = require("@/api/warehouseArea");
var _vuex = require("vuex");
var _supplier = require("@/api/supplier");
var _vueSelect = _interopRequireDefault(require("vue-select"));
require("vue-select/src/scss/vue-select.scss");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _pluralize = _interopRequireDefault(require("pluralize"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getRawMaterial } from '@/api/rawMaterial'
// import AddItemDialog from './add-item-dialog'
var _default = {
  components: {
    vSelect: _vueSelect.default /* ,
                                AddItemDialog */
  },

  data() {
    return {
      loading: false,
      suppliers: [],
      warehouses: [],
      materials: '',
      createMode: true,
      dialogFormVisible: false,
      dialogCopyFormVisible: false,
      confirmed_allocated: true,
      goodReceivedNote: {
        id: '',
        uuid: '',
        displayId: '',
        status: 'Drafted',
        number: '',
        storeId: null,
        remark: '',
        items: []
      },
      roleNames: [],
      rawMaterialAreaMap: {},
      disabled: 0,
      buttonText: _i18n.default.t('GoodReceivedNotes.Save'),
      buttonSubmit: _i18n.default.t('GoodReceivedNotes.Submit'),
      buttonDelete: _i18n.default.t('GoodReceivedNotes.Delete'),
      buttonUnsubmit: _i18n.default.t('GoodReceivedNotes.Unsubmit'),
      buttonConfirm: _i18n.default.t('GoodReceivedNotes.Confirm'),
      buttonReject: _i18n.default.t('GoodReceivedNotes.Reject'),
      buttonComplete: _i18n.default.t('GoodReceivedNotes.Complete'),
      buttonDownload: _i18n.default.t('GoodReceivedNotes.DownloadGRN'),
      buttonDownloadTransitNote: _i18n.default.t('GoodReceivedNotes.DownloadTN'),
      rules: {
        status: {
          required: true,
          message: _i18n.default.t('GoodReceivedNotes.RequiredMessage'),
          trigger: 'change'
        },
        supplierId: {
          required: true,
          message: _i18n.default.t('GoodReceivedNotes.RequiredMessage')
        }
      },
      options: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['roles']),
    device() {
      return this.$store.state.app.device;
    },
    canConfirm: {
      get() {
        return this.roleNames && (this.roleNames.includes('Admin') || this.roleNames.includes('Procurement Manager'));
      }
    },
    drafted: {
      get() {
        return this.goodReceivedNote && this.goodReceivedNote.status === 'Drafted';
      }
    },
    confirmed: {
      get() {
        return this.goodReceivedNote && this.goodReceivedNote.status === 'Confirmed';
      }
    },
    completed: {
      get() {
        return this.goodReceivedNote && this.goodReceivedNote.status === 'Completed';
      }
    },
    recievedtype: {
      get() {
        return this.goodReceivedNote && this.goodReceivedNote.type === 'received';
      }
    },
    canceledtype: {
      get() {
        return this.goodReceivedNote && this.goodReceivedNote.type === 'canceled';
      }
    },
    total: {
      get() {
        // const total = 0
        // https://stackoverflow.com/questions/48856902/sum-of-float-and-int-returns-unusual-decimal-places-js
        return this.goodReceivedNote.items.length;
      }
    }
  },
  async created() {
    this.confirmed_allocated = false;
    const warehousesPromise = (0, _warehouse.getWarehouses)({
      limit: 100,
      withAreas: 'true'
    }).then(response => {
      this.warehouses = response.data;
      response.data.forEach(async warehouse => {
        if (warehouse.areas && warehouse.areas.length) {
          this.rawMaterialAreaMap[warehouse.uuid] = warehouse.areas;
        }
        if (!this.rawMaterialAreaMap[warehouse.uuid]) {
          (0, _warehouseArea.getWarehouseAreas)({
            warehouseId: warehouse.uuid,
            limit: 9999
          }).then(warehouseAreas => {
            this.rawMaterialAreaMap[warehouse.uuid] = warehouseAreas.data;
          });
        }
        // console.log('warehouseAreas.data', warehouseAreas.data)
      });
    });

    let fetchPromise = Promise.resolve();
    if (this.$route.params.id) {
      // this.buttonText = 'Save'
      this.disabled = 1;
      fetchPromise = this.fetchData(warehousesPromise);
      this.createMode = false;
    } else {
      this.createMode = true;
      // this.buttonText = 'Save'
    }

    fetchPromise.then(() => {
      if (!this.confirmed && !this.completed) {
        (0, _supplier.getSuppliers)({
          perPage: 999
        }).then(response => {
          this.suppliers = response.data;
        });
      } else {
        this.suppliers = [this.goodReceivedNote.supplier];
      }
    });
    this.roleNames = this.roles.map(role => role.name);
  },
  methods: {
    getWarehouseAreasForWarehouseRawMaterial(rawMaterialId, warehouseId) {
      if (this.rawMaterialAreaMap[warehouseId]) {
        // console.log('getWarehouseAreasForWarehouseRawMaterial', this.rawMaterialAreaMap[rawMaterialId], warehouseId)
        return this.rawMaterialAreaMap[warehouseId];
      }
      return [];
    },
    transferedTotal(goodReceivedItemId) {
      let count = 0;
      this.goodReceivedNote.items.forEach(item => {
        if (item.id === goodReceivedItemId && item.transferNotes) {
          item.transferNotes.forEach(transferNote => {
            count += transferNote.qty * 100;
          });
        }
      });
      return count / 100;
    },
    handleAddItemDialogComplete(rawMaterials) {
      rawMaterials.forEach(rawMaterial => {
        const item = {};
        item.rawMaterial = rawMaterial;
        item.qty = 0;
        item.unitPrice = rawMaterial.defaultPrice;
        item.remark = null;
        this.goodReceivedNote.items.push(item);
        this.dialogFormVisible = false;
      });
    },
    handleCopyItemDialogComplete(rawMaterial) {
      const item = {};
      item.rawMaterial = rawMaterial;
      item.qty = 0;
      item.unitPrice = rawMaterial.defaultPrice;
      item.remark = null;
      this.goodReceivedNote.items.push(item);
      this.dialogFormVisible = false;
    },
    /* addRow() {
      this.dialogFormVisible = true
    }, */
    generateText() {
      this.dialogCopyFormVisible = true;
    },
    pluralize(unit) {
      if (['kg', 'gr', 'l', 'ml', 'mg'].indexOf(unit) === -1) {
        return (0, _pluralize.default)(unit);
      } else {
        return unit;
      }
    },
    fetchData(warehousesPromise = Promise.resolve()) {
      this.loading = true;
      return (0, _goodReceivedNote.getGoodReceivedNote)(this.$route.params.id).then(response => {
        this.goodReceivedNoteNumber = response.data.displayId;
        return warehousesPromise.then(() => {
          response.data.items.forEach(item => {
            item.showItemQty = true;
            if (item.poQty - (item.poReceivedQTY + item.poCanceledQTY) <= 0 && response.data.status === 'Drafted') {
              item.showItemQty = false;
            }
            // console.log('item.transferNotes',item.transferNotes);
            // this.rawMaterialAreaMap[item.rawMaterial.uuid] = []
            const transferNotes = [];

            // const warehouses = (await getRawMaterial(item.rawMaterial.uuid)).data.warehouses
            for (const warehouse of this.warehouses) {
              transferNotes.push({
                qty: 0,
                warehouse: {
                  name: warehouse.name,
                  uuid: warehouse.uuid
                },
                requestedQty: 0,
                transferredQty: 0,
                warehouseAreaId: null
              });
            }
            if (transferNotes) {
              item.transferNotes.forEach(transferNote => {
                transferNotes.forEach(myTransferNote => {
                  if (transferNote.warehouse.uuid === myTransferNote.warehouse.uuid) {
                    myTransferNote.id = transferNote.id;
                    myTransferNote.warehouseAreaId = transferNote.area ? transferNote.area.uuid : null;
                    myTransferNote.requestedQty = transferNote.requestedQty;
                    myTransferNote.transferredQty = transferNote.transferredQty;
                    myTransferNote.qty += transferNote.qty;
                  }
                });
              });
              item.transferNotes = transferNotes.sort((a, b) => {
                if (a.transferredQty && b.transferredQty) {
                  if (a.transferredQty < b.transferredQty) return 1;
                  if (a.transferredQty > b.transferredQty) return -1;
                } else if (a.transferredQty && b.requestedQty && !b.requestedQty) {
                  return 1;
                } else {
                  if (a.requestedQty < b.requestedQty) return 1;
                  if (a.requestedQty > b.requestedQty) return -1;
                }
                return 0;
              });
              item.transferNotes.forEach(transferNote => {
                // Default received qty if not yet input qty
                if (transferNote.requestedQty > 0 && transferNote.transferredQty === 0 && transferNote.qty === 0) {
                  transferNote.qty = transferNote.requestedQty;
                }
                // console.log({transferNote})
                if (!transferNote.warehouseAreaId) {
                  const areas = this.getWarehouseAreasForWarehouseRawMaterial(item.rawMaterial.uuid, transferNote.warehouse.uuid);
                  if (areas && areas.length) {
                    transferNote.warehouseAreaId = areas[areas.length - 1].uuid;
                  }
                }
              });
            }

            // item.transferNotes = item.transferNotes
          });

          response.data.supplierId = response.data.supplier.uuid;
          this.goodReceivedNote = response.data;
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    handleDelete(index) {
      this.goodReceivedNote.items.splice(index, 1);
    },
    checkTransferArea() {
      let hasNoAreaTransfer = false;
      this.goodReceivedNote.items.forEach(item => {
        item.transferNotes.forEach(transferNote => {
          if (!transferNote.warehouseAreaId && transferNote.qty) {
            hasNoAreaTransfer = true;
          }
        });
      });
      if (hasNoAreaTransfer) {
        (0, _elementUi.Message)({
          message: _i18n.default.t('GoodReceivedNotes.InvalidInput'),
          type: 'error',
          duration: 5 * 1000
        });
        return false;
      }
      return true;
    },
    calculateRoundStep(value, max) {
      const percentValue = parseFloat((value - parseFloat((Math.floor(value * 10) / 10).toFixed(2))).toFixed(2)) * 100;
      const percentMax = parseFloat((max - parseFloat((Math.floor(max * 10) / 10).toFixed(2))).toFixed(2)) * 100;
      if (percentValue < 10 && percentValue > 0 || percentMax < 10 && percentMax > 0) {
        if (!(percentValue % 2) && !((max * 100 - percentValue) % 2)) {
          return 0.02;
        } else {
          return 0.01;
        }
      }
      const floatValue = parseFloat((value - parseFloat(Math.floor(value).toFixed(2))).toFixed(2)) * 10;
      const floatMax = parseFloat((max - parseFloat(Math.floor(max).toFixed(2))).toFixed(2)) * 10;
      if (floatValue < 10 && floatValue > 0 || floatMax < 10 && floatMax > 0) {
        if (!(floatValue % 2) && !((max * 10 - floatValue) % 2)) {
          return 0.2;
        } else {
          return 0.1;
        }
      }
      if (value < 10) {
        if (!(value % 2) && !((max - value) % 2)) {
          return 2;
        } else {
          return 1;
        }
      }
      if (value > 10) {
        if (!(value % 100) && !((max - value) % 100)) {
          return 100;
        } else if (!(value % 50) && !((max - value) % 50)) {
          return 50;
        } else if (!(value % 10) && !((max - value) % 10)) {
          return 10;
        } else if (!(value % 5) && !((max - value) % 5)) {
          return 5;
        } else {
          return 1;
        }
      }
      return 0.1;
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }
        if (!this.checkTransferArea()) return false;
        this.loading = true;
        if (this.$route.params.id) {
          return (0, _goodReceivedNote.editGoodReceivedNote)({
            uuid: this.$route.params.id,
            items: [...this.goodReceivedNote.items.filter(item => item.qty).map(item => ({
              id: item.id,
              rawMaterial: {
                uuid: item.rawMaterial.uuid
              },
              remark: item.remark,
              qty: item.qty,
              transferNotes: [...item.transferNotes.filter(item => item.qty).map(item => ({
                id: item.id,
                warehouseAreaId: item.warehouseAreaId,
                qty: item.qty
              }))]
            }))],
            supplierId: this.goodReceivedNote.supplierId,
            remark: this.goodReceivedNote.remark,
            goodReceivedDate: this.goodReceivedNote.goodReceivedDate,
            poNum: this.goodReceivedNote.purchaseOrder.displayId,
            type: this.goodReceivedNote.type
          }).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('GoodReceivedNotes.PurchaseRequestUpdated'),
              type: 'success',
              duration: 5 * 1000
            });
            if (this.$workbox && this.$workbox.active instanceof Promise) {
              const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
              return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
                if (value !== uniqueValue) {
                  return this.fetchData().then(() => {
                    this.$router.go();
                  });
                } else {
                  this.$router.go();
                }
              }, () => {
                this.$router.go();
              });
            } else {
              this.$router.go();
            }
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('GoodReceivedNotes.UpdateFailed'),
              type: 'error',
              duration: 5 * 1000
            });
            // console.log(err)
            this.loading = false;
          });
        } else {
          return (0, _goodReceivedNote.createGoodReceivedNote)({
            items: [...this.goodReceivedNote.items.filter(item => item.qty).map(item => ({
              id: item.id,
              rawMaterial: {
                uuid: item.rawMaterial.uuid
              },
              remark: item.remark,
              qty: item.qty,
              transferNotes: [...item.transferNotes.filter(item => item.qty).map(item => ({
                id: item.id,
                warehouseAreaId: item.warehouseAreaId,
                qty: item.qty
              }))]
            }))],
            supplierId: this.goodReceivedNote.supplierId,
            remark: this.goodReceivedNote.remark,
            goodReceivedDate: this.goodReceivedNote.goodReceivedDate,
            poNum: this.goodReceivedNote.purchaseOrder.displayId,
            type: this.goodReceivedNote.type
          }).then(response => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('GoodReceivedNotes.PurchaseRequestCreated'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/good-received-notes/edit/` + response.data.uuid);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('GoodReceivedNotes.CreateFailed'),
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    },
    deleteGoodReceivedNote() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('GoodReceivedNotes.ConfirmDelete'), 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _goodReceivedNote.deleteGoodReceivedNote)(this.goodReceivedNote.uuid).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      }))).then(() => {
        (0, _elementUi.Message)({
          message: _i18n.default.t('GoodReceivedNotes.Deleted'),
          type: 'success',
          duration: 5 * 1000
        });
        this.$router.push(`/good-received-notes`);
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err)).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('GoodReceivedNotes.DeleteFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    confirmGoodReceivedNote() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('GoodReceivedNotes.ConfirmRequest'), 'Confirm', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        if (!this.checkTransferArea()) return false;
        return (0, _goodReceivedNote.editGoodReceivedNote)({
          uuid: this.$route.params.id,
          items: [...this.goodReceivedNote.items.filter(item => item.qty).map(item => ({
            id: item.id,
            rawMaterial: {
              uuid: item.rawMaterial.uuid
            },
            remark: item.remark,
            qty: item.qty,
            transferNotes: [...item.transferNotes.filter(item => item.qty).map(item => ({
              id: item.id,
              warehouseAreaId: item.warehouseAreaId,
              qty: item.qty
            }))]
          }))],
          supplierId: this.goodReceivedNote.supplierId,
          remark: this.goodReceivedNote.remark,
          goodReceivedDate: this.goodReceivedNote.goodReceivedDate,
          poNum: this.goodReceivedNote.purchaseOrder.displayId,
          type: this.goodReceivedNote.type
        }).then(() => {
          return (0, _goodReceivedNote.confirmGoodReceivedNote)(this.goodReceivedNote.uuid).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('GoodReceivedNotes.ConfirmRequest'),
              type: 'success',
              duration: 5 * 1000
            });
            if (this.$workbox && this.$workbox.active instanceof Promise) {
              const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
              return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
                if (value !== uniqueValue) {
                  return this.fetchData().then(() => {
                    this.$router.go();
                  });
                } else {
                  this.$router.go();
                }
              }, () => {
                this.$router.go();
              });
            } else {
              this.$router.go();
            }
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('GoodReceivedNotes.ConfirmRequestFailed'),
              type: 'error',
              duration: 5 * 1000
            });
          });
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('GoodReceivedNotes.ConfirmRequestFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    completeGoodReceivedNote() {
      this.confirmed_allocated ? _elementUi.MessageBox.confirm('Confirm to confirm request?', 'Confirm', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        if (!this.checkTransferArea()) return false;
        return (0, _goodReceivedNote.editGoodReceivedNote)({
          uuid: this.$route.params.id,
          items: [...this.goodReceivedNote.items.filter(item => item.qty).map(item => ({
            id: item.id,
            rawMaterial: {
              uuid: item.rawMaterial.uuid
            },
            remark: item.remark,
            qty: item.qty,
            transferNotes: [...item.transferNotes.filter(item => item.qty).map(item => ({
              id: item.id,
              warehouseAreaId: item.warehouseAreaId,
              qty: item.qty
            }))]
          }))],
          supplierId: this.goodReceivedNote.supplierId,
          remark: this.goodReceivedNote.remark,
          goodReceivedDate: this.goodReceivedNote.goodReceivedDate,
          poNum: this.goodReceivedNote.purchaseOrder.displayId,
          type: this.goodReceivedNote.type
        }).then(() => {
          /*
          completeGoodReceivedNote(this.goodReceivedNote.uuid).then(() => {
            Message({
              message: i18n.t('GoodReceivedNotes.RequestCompleted'),
              type: 'success',
              duration: 5 * 1000
            })
            this.$router.go()
          }).catch(err => {
            Message({
              message: parseError(err) || i18n.t('GoodReceivedNotes.CompleteRequestFailed'),
              type: 'error',
              duration: 5 * 1000
            })
          })
          */
          if (this.$workbox && this.$workbox.active instanceof Promise) {
            const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
            return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
              if (value !== uniqueValue) {
                return this.fetchData().then(() => {
                  this.$router.go();
                });
              } else {
                this.$router.go();
              }
            }, () => {
              this.$router.go();
            });
          } else {
            this.$router.go();
          }
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('GoodReceivedNotes.CompleteRequestFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err)) : _elementUi.MessageBox.alert('Please do not mark this as complete before allocation').catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    onCancel() {
      if (this.goodReceivedNote.status === 'Drafted') {
        this.$router.push(`/good-received-notes/drafted`);
      } else if (this.goodReceivedNote.status === 'Confirmed') {
        this.$router.push(`/good-received-notes/confirmed`);
      } else if (this.goodReceivedNote.status === 'Completed') {
        this.$router.push(`/good-received-notes/completed`);
      } else {
        this.$router.push(`/good-received-notes`);
      }
    },
    onBackToPO() {
      if (this.goodReceivedNote.purchaseOrder.status === 'Confirmed') {
        this.$router.push(`/purchase-orders/confirmed`);
      } else if (this.goodReceivedNote.purchaseOrder.status === 'Completed') {
        this.$router.push(`/purchase-orders/completed`);
      } else if (this.goodReceivedNote.purchaseOrder.status === 'Outstanding') {
        this.$router.push(`/purchase-orders/outstanding`);
      } else {
        this.$router.push(`/purchase-orders/edit/` + this.goodReceivedNote.purchaseOrder.uuid);
      }
    },
    downloadGoodReceivedNote(id, displayId) {
      (0, _goodReceivedNote.downloadGoodReceivedNote)(id).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${displayId}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadGoodReceivedNoteTransitNote(id, displayId) {
      (0, _goodReceivedNote.downloadGoodReceivedNoteTransitNote)(id).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${displayId}_transit.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }
  }
};
exports.default = _default;